class Service {
  id: string;
  name: string | null;
  description: string | null;

  constructor(
    id: string,
    name: string | null = null,
    description: string | null = null
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
  }
}

class Product {
  id: string;
  name: string | null;
  tags: string[];
  description: string | null;
  href: string | null;

  constructor(
    id: string,
    name: string | null = null,
    tags: string[] = [],
    description: string | null = null,
    href: string | null = null
  ) {
    this.id = id;
    this.name = name;
    this.tags = tags;
    this.description = description;
    this.href = href;
  }
}

export { Service, Product };

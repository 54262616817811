
import Vue from "vue";
import { products, services } from "@/resources";
import ServiceRow from "@/components/ServiceRow.vue";
import ProductCard from "@/components/ProductCard.vue";

export default Vue.extend({
  name: "App",
  components: { ProductCard, ServiceRow },
  data: () => ({
    offsetTop: 0,
    sections: [
      { id: "intro", name: "公司介紹" },
      { id: "services", name: "服務項目" },
      { id: "products", name: "產品範例" },
      { id: "contact", name: "聯絡我們" },
    ],
  }),
  computed: {
    services() {
      return services;
    },
    products() {
      return products;
    },
    atTop() {
      return this.offsetTop < 200;
    },
  },
  methods: {
    onScroll(e: { target: { scrollingElement: { scrollTop: number } } }) {
      this.offsetTop = e?.target?.scrollingElement.scrollTop;
    },
  },
});

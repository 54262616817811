import { Product, Service } from "@/models";

const services: Service[] = [
  new Service(
    "webDev",
    "網站設計與開發",
    "提供企業形象網站、部落格等設計與開發服務。<br>" +
      "分析使用者需求並根據情境設計優良的使用流程。<br>" +
      "以Vue網頁框架建立響應式單頁應用，滿足跨平台、跨裝置的瀏覽體驗。<br>" +
      "也提供網站上線、營運維護服務。"
  ),
  new Service(
    "backDev",
    "後端程式開發",
    "使用Python Flask、FastAPI等框架建立後端API伺服器。<br>" +
      "串接SQL、MongoDB資料庫與其他外部服務。<br>" +
      "Python網頁爬蟲開發，蒐集網頁資訊並整理為易於使用的資料庫格式。<br>" +
      "提供軟體架構規劃服務，建立優良的業務邏輯模型、資料庫與API規劃。"
  ),
  new Service(
    "cyberSecurityConsulting",
    "資安顧問服務",
    "提供中小企業資安規劃與導入建議。<br>" +
      "為組織分析並規劃合適的網路結構，降低被駭客入侵、勒索軟體的風險。<br>" +
      "提供組織人員資安教育訓練服務，提高整體資安意識。"
  ),
];

const products: Product[] = [
  new Product(
    "cssp",
    "資安技能點網站",
    ["網站開發", "Vue", "圖表設計", "資安知識"],
    "資安技能點網站介紹了當今資安產業常見職位與其所需技能，為希望加入資安業界的人才提供學習方向的指引。",
    "https://cssp.alyssum.com.tw"
  ),
  new Product(
    "foodRatingCard",
    "美食評分卡",
    ["網站開發", "Vue"],
    "美食評分卡讓你簡單點綴美食照片，",
    "https://food-rating-card.alyssum.com.tw"
  ),
  new Product(
    "stemPlus",
    "國小STEM+教學中心官方網站",
    ["網站開發", "WordPress"],
    "「國小STEM+ 教學中心」之設立宗旨為培育優秀國小STEM+師資、促進國小在職教師STEM+專業能力成長、以及國小STEM+教材教法之研發。"
  ),
];

export { services, products };

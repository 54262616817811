<template lang="pug">
  v-card(height="100%")
    v-img(:src="getImageSrc()" :aspect-ratio="16/9")
      template(v-slot:placeholder)
        v-row.fill-height.ma-0(align="center" justify="center")
          v-progress-circular(indeterminate color="grey lighten-5")
    v-card-title {{ product.name }}
    v-card-subtitle
      v-chip-group
        v-chip(v-for="tag in product.tags" small) {{ tag }}
    v-card-text {{ product.description }}
    v-card-actions(v-if="product.href !== null")
      v-btn(text :href="product.href")
        | 查看
</template>

<script>
import { Product } from "@/models";

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Product,
      required: true,
    },
  },
  methods: {
    getImageSrc() {
      try {
        return require(`../assets/products/${this.product.id}.webp`);
      } catch (e) {
        console.log(
          `Image "../assets/products/${this.product.id}.webp" not found.`
        );
        return require(`@/assets/products/productDefault.svg`);
      }
    },
  },
};
</script>

<style scoped></style>

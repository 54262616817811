<template lang="pug">
  v-row(justify="center")
    v-col(cols="4" :order="reverse ? 0:1")
      v-img(:src="getImageSrc()" :aspect-ratio="4/3")
        template(v-slot:placeholder)
          v-row.fill-height.ma-0(align="center" justify="center")
            v-progress-circular(indeterminate color="grey lighten-5")
    v-col(cols="4" align-self="center")
      h3.mb-3 {{ service.name }}
      p(v-html="service.description")
</template>

<script>
import { Service } from "@/models";

export default {
  name: "ServiceRow",
  props: {
    service: {
      type: Service,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getImageSrc() {
      try {
        return require(`../assets/services/${this.service.id}.webp`);
      } catch (e) {
        console.log(
          `Image "../assets/services/${this.service.id}.webp" not found.`
        );
        return null;
      }
    },
  },
};
</script>

<style scoped></style>
